.skills-main-div {
  display: flex;
}

.skills-header {
  font-size: 60px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.skills-text {
  font-size: 20px;
}

.skills-text-div {
  margin-left: 50px;
  margin-top: 3%;
  padding-top: 80px;
  font-family: "Google Sans Regular";
}

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div > * {
  max-width: 100%;
  height: auto;
  margin-top: 50px;
}

.skills-heading {
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
  font-family: "Google Sans Regular";
}

.image-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-link {
  text-align: center;
  margin: 0 10px; 
  flex: 1 0 50%; 
  padding-bottom: 25px
}

.responsive-image {
  width: 80%;
  height: 400px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensure the image covers the specified dimensions */
}

/* Media query for smaller screens (mobile) */
@media (max-width: 768px) {
  .image-link {
    flex: 1 0 100%; /* Each image takes up 100% of the available width */
    margin: 10px 0; /* Add margin to separate images vertically */
  }
}


/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
  .skills-header {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }
  .skills-header {
    font-size: 30px;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column;
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    order: 2;
  }
  .skills-image-div > * {
    margin-top: 0px;
  }
}
