.educations-header {
  font-size: 40px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

@media (max-width: 1380px) {
  .educations-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .educations-header {
    font-size: 30px;
  }
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.gallery-item {
  margin: 9px;
}

.gallery-image {
  width: 100%;
  height: 290px;
  object-fit: cover;
}

@media (max-width: 768px) {
  /* For screens smaller than 768px (typical mobile devices) */
  .gallery-item {
    width: 90%;
    margin: 5px auto;
  }
}
